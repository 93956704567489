
import { Options, Vue } from "vue-class-component";
import CourseCard from "../components/CourseCard.vue";
import { CourseType } from "../types/course/course-type";
import store from "../store";
import { courseAllHandler } from "../handlers/course/course-plain-handlers";
import { courseListAvailibleHandler, courseListEnrolledHandler } from "../handlers/course/course-get-auth-handlers";
import Loading from "../components/Loading.vue";
import { ParsedCourseType } from "../types/ui/parsed-courses";
import { TypeSubjectEnum } from "../types/enum/type-course";
import { splitToArrays } from "../helpers/split-to-arrays";

@Options({
  components: { Loading, CourseCard },
})
export default class Courses extends Vue {
  // Login types
  private course_mysubjects_list: Array<CourseType> | undefined = [];
  private course_mysubjects_list_parsed: Array<ParsedCourseType> = [];

  private course_availible_list: Array<CourseType> = [];
  private course_availible_list_parsed: Array<ParsedCourseType> = [];

  // Unregistered
  private course_general_list: Array<CourseType> | undefined = [];
  private course_general_list_parsed: Array<ParsedCourseType> = [];

  // TODO: Add getter
  private type_general: TypeSubjectEnum = TypeSubjectEnum.GENERAL;
  private type_my: TypeSubjectEnum = TypeSubjectEnum.MY;
  private type_taught: TypeSubjectEnum = TypeSubjectEnum.TAUGHT;

  isLoggedIn = (): boolean => store.getters.isLoggedIn;

  mounted(): void {
    if (this.isLoggedIn()) {
      courseListEnrolledHandler()
        .then((courseAllGet: Array<CourseType> | void) => {
          this.course_mysubjects_list = courseAllGet || [];
          this.course_mysubjects_list_parsed = splitToArrays(this.course_mysubjects_list);
        })
        .catch((error) => {
          console.error(error);
        });
      courseListAvailibleHandler()
        .then((courseAllGet: Array<CourseType> | void) => {
          this.course_availible_list = courseAllGet || [];
          this.course_availible_list_parsed = splitToArrays(this.course_availible_list);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      courseAllHandler()
        .then((courseAllGet: Array<CourseType> | void) => {
          this.course_general_list = courseAllGet || [];
          this.course_general_list_parsed = splitToArrays(this.course_general_list);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
